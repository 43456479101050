import React, { useState, useRef } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import "../styles/banner.css"
import "../styles/podcast.css"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import HubspotForm from "react-hubspot-form"
import watch from "../images/watch-trailer.svg"
import spark from "../images/spark-of-ages.png"
import playicon from "../images/playicon.svg"
import clockicon from "../images/clock-icon.svg"
import playiconsmall from "../images/playicon-small.svg"
import podcastimage from "../images/most-recent-episodes.jpg"
import loadmorebtn from "../images/arrow-down.svg"

import LogoOne from "../images/amazon-music.svg"
import LogoTwo from "../images/apple-podcasts.svg"
import LogoThree from "../images/deezer.svg"
import LogoFour from "../images/iheartraduo.svg"
import LogoFive from "../images/listennotes.svg"
import LogoSix from "../images/pocketcasts.svg"
import LogoSeven from "../images/podcast-index.svg"
import LogoEight from "../images/podchaser.svg"
import LogoNine from "../images/podxast-addict.svg"
import LogoTen from "../images/spotify.svg"
import LogoEleven from "../images/tuneln-alexa.svg"
import LogoTwelve from "../images/google-podcasts.svg"

import ModalVideo from "react-modal-video"
import ReactImageVideoLightbox from "react-image-video-lightbox"
import ReactAudioPlayer from "react-audio-player"

const Podcast = ({ data }) => {
  const firstthree = data.firstthreeepisode
  const restall = data.restallepisode

  const [isOpen, setOpen] = useState(false)
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)

  const handleLightBoxOpen = () => {
    setIsLightBoxOpen(true)
  }
  const handleLightBoxClose = () => {
    setIsLightBoxOpen(false)
  }
  const [activePlayerIndex, setActivePlayerIndex] = useState(null)

  const playAudio = index => {
    if (activePlayerIndex !== null) {
      const prevAudioElement = document.getElementById(
        `audio-player-${activePlayerIndex}`
      )
      if (prevAudioElement) {
        prevAudioElement.pause()
      }
    }
    setActivePlayerIndex(index)
    const audioElement = document.getElementById(`audio-player-${index}`)
    if (audioElement) {
      audioElement.play()
    }
  }

  const closeAudio = index => {
    setActivePlayerIndex(null)

    const audioElement = document.getElementById(`audio-player-${index}`)

    if (audioElement) {
      audioElement.pause()
    }
  }

  const [displayCount, setDisplayCount] = useState(6)

  const loadMoreEpisodes = () => {
    setDisplayCount(displayCount + 6)
  }

  const noResultsFound = displayCount >= restall.edges.length

  return (
    <>
      {data.allWpPage.edges.map(({ node }) => (
        <Helmet>
          <title>{node.title} | Position2</title>
          <meta name="description" content={node.seo.metaDesc} />
          <body className={node.slug} />
        </Helmet>
      ))}
      <Layout>
        <div class="podcast-banner-wrap">
          <div class="container">
            <div class="podcast-banner">
              <div class="banner-text">
                {/* {data.allWpPage.edges.map(({ node }) => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        node.videoBannerWithBtitleDescriptionCta
                          .descriptionArea,
                    }}
                  ></div>
                ))} */}
                <h1>
                  Welcome to the <br />
                  <strong>Spark of Ages Podcast</strong>
                </h1>
                <p>
                  We're going to talk to founders, innovators, CEOs, investors,
                  designers & artists, -{" "}
                  <strong>we're talking game changers OF ALL KINDS</strong>{" "}
                  about their big, world-shaping ideas, and what sparked them.
                </p>
                <div class="CTA">
                  <a href="#SpeakerSection">Start listening</a>
                </div>
              </div>
              <div class="banner-img">
                <div class="img">
                  <img src={spark} alt="spark-of-ages" />
                </div>
                <div class="watch-trailer"></div>
                <div class="watch-trailer-text">
                  <img src={watch} alt="spark-of-ages" />
                </div>
                <div class="play-icon" onClick={handleLightBoxOpen}>
                  <div class="circle"></div>
                  <img src={playicon} alt="Play icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLightBoxOpen && (
          <div class="video-popup">
            <ReactImageVideoLightbox
              data={[
                {
                  url: "https://player.vimeo.com/video/877823861?h=e6c341e6b7",
                  type: "video",
                  title: "Placeholder video",
                },
                {
                  url: "https://player.vimeo.com/video/877823914?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479",
                  type: "video",
                  title: "Placeholder video",
                },
                {
                  url: "https://player.vimeo.com/video/877823956?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479",
                  type: "video",
                  title: "Placeholder video",
                },
                {
                  url: "https://player.vimeo.com/video/877823991?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479",
                  type: "video",
                  title: "Placeholder video",
                },
              ]}
              startIndex={0}
              showResourceCount={true}
              onCloseCallback={handleLightBoxClose}
              onNavigationCallback={currentIndex =>
                console.log(`Current index: ${currentIndex}`)
              }
            />
          </div>
        )}
        <ModalVideo
          channel="vimeo"
          autoplay
          isOpen={isOpen}
          videoId="877823861"
          onClose={() => setOpen(false)}
        />
        <div className="get-notified-wrap">
          <div className="container">
            <div className="get-notified">
              {data.allWpPage.edges.map(({ node }) => (
                <div
                  className="author-info"
                  dangerouslySetInnerHTML={{
                    __html: node.singleBlockLayoutSection.textArea,
                  }}
                ></div>
              ))}
              <div className="get-notified-text">
                <h2>Get notified when an episode drops</h2>
                <div className="get-notified-form">
                  <HubspotForm
                    region="na1"
                    portalId="2778625"
                    formId="5adc088a-e25e-41fe-8f77-c8f5ee002b42"
                    onSubmit={() => console.log("Submit!")}
                    onReady={form => console.log("Form ready!")}
                    loading={<div></div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="episodes-logo-sec">
          <div class="container">
            <div class="title">
              <h3>
                We'll drop new episodes here, but you can listen to the Spark of
                Ages podcast on your favorite platforms.
              </h3>
            </div>
            <div class="episodes-logo">
              <div class="each-logo">
                <img src={LogoOne} alt="Amazon Music" />
              </div>
              <div class="each-logo">
                <img src={LogoTwo} alt="Apple Podcasts" />
              </div>
              <div class="each-logo">
                <img src={LogoThree} alt="Deezer" />
              </div>
              <div class="each-logo">
                <img src={LogoFour} alt="iHeartRadio" />
              </div>
              <div class="each-logo">
                <img src={LogoFive} alt="Listen Notes" />
              </div>
              <div class="each-logo">
                <img src={LogoSix} alt="Pocket Casts" />
              </div>
              <div class="each-logo">
                <img src={LogoSeven} alt="Podcast Index" />
              </div>
              <div class="each-logo">
                <img src={LogoEight} alt="PodChaser" />
              </div>
              <div class="each-logo">
                <img src={LogoNine} alt="Podcast Addict" />
              </div>
              <div class="each-logo">
                <img src={LogoTen} alt="Spotify" />
              </div>
              <div class="each-logo">
                <img src={LogoEleven} alt="TueIn+Alexa" />
              </div>
              <div class="each-logo">
                <img src={LogoTwelve} alt="Google Podcasts" />
              </div>
            </div>
          </div>
        </div>
        <div class="most-recent-episodes clearfix" id="SpeakerSection">
          <div class="container">
            <div class="title">
              <h3>Most recent episodes</h3>
            </div>
            {/* <div class="three-col episodes-wrap clearfix">
              {firstthree.edges.map(({ node }, index) => (
                <div class="each-episodes">
                  <div class="text">
                    <div class="ep-num">{node.episodeDetails.eyebrow}</div>
                    <h4>{node.title}</h4>
                    <p>
                      <span>{node.episodeDetails.author}</span> /{" "}
                      <span>1{node.episodeDetails.date}</span>
                    </p>
                    <div class="time">
                      <img src={clockicon} alt="Clock Iocn" />
                      <span>{node.episodeDetails.duration}</span>
                    </div>
                  </div>
                  <div
                    class="play-icon"
                    onClick={() => playAudio(index)}
                    style={{
                      display: activePlayerIndex === index ? "none" : "block",
                    }}
                  >
                    <div class="circle"></div>
                    <img src={playiconsmall} alt="Play Icon" />
                  </div>
                  <div
                    class="audio-player"
                    style={{
                      display: activePlayerIndex === index ? "block" : "none",
                    }}
                  >
                    <ReactAudioPlayer
                      id={`audio-player-${index}`} // Unique ID for each audio element
                      src={node.episodeDetails.episodeUrl}
                      controls
                    />
                    <div class="closebtn" onClick={() => closeAudio(index)}>
                      X
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            <div class="more-episodes clearfix">
              {restall.edges.slice(0, displayCount).map(({ node }, index) => (
                <React.Fragment key={node.id}>
                  <div
                    className={index >= 3 ? "old-episode" : "each-episodes"}
                    key={index}
                  >
                    <div className="innerholder">
                      <div
                        class="img"
                        style={{
                          backgroundImage: `url(${
                            node.episodeDetails.podcastImage
                              ? node.episodeDetails.podcastImage.node.sourceUrl
                              : podcastimage
                          })`,
                        }}
                      ></div>
                      <div class="text">
                        <div class="ep-num">{node.episodeDetails.eyebrow}</div>
                        <h4>{node.episodeDetails.title}</h4>
                        <p>
                          <span>{node.episodeDetails.author}</span> /{" "}
                          <span>{node.episodeDetails.date}</span>
                        </p>
                        <div class="time">
                          <img src={clockicon} alt="Clock Iocn" />
                          <span>{node.episodeDetails.duration}</span>
                        </div>
                      </div>
                      <div
                        class="play-icon"
                        onClick={() => playAudio(index)}
                        style={{
                          display:
                            activePlayerIndex === index ? "none" : "block",
                        }}
                      >
                        <div class="circle"></div>
                        <img src={playiconsmall} alt="Play Icon" />
                      </div>
                      <div
                        class="audio-player"
                        style={{
                          display:
                            activePlayerIndex === index ? "block" : "none",
                        }}
                      >
                        <ReactAudioPlayer
                          id={`audio-player-${index}`} // Unique ID for each audio element
                          src={node.episodeDetails.episodeUrl}
                          controls
                        />
                        <div class="closebtn" onClick={() => closeAudio(index)}>
                          X
                        </div>
                      </div>
                    </div>
                  </div>
                  {index === 2 && (
                    <div className="title old-episodes-title">
                      <h3>Episodes</h3>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            {displayCount < restall.edges.length && (
              <div className="load-more-btn">
                <button onClick={loadMoreEpisodes}>
                  <span>Load More</span>
                  <img src={loadmorebtn} alt="arrow down"></img>
                </button>
              </div>
            )}
            {noResultsFound && (
              <div className="no-results-found ">
                <p>No more results found</p>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}
export default function PodcastBlock(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: { id: { eq: "cG9zdDoxNzkwMw==" } }) {
            edges {
              node {
                id
                title
                slug
                uri
                logosSection {
                  logos {
                    image {
                      node {
                        altText
                        localFile {
                          publicURL
                        }
                      }
                    }
                  }
                }
                singleBlockLayoutSection {
                  textArea
                }
                seo {
                  metaDesc
                }
                videoBannerWithBtitleDescriptionCta {
                  descriptionArea
                  title
                }
              }
            }
          }
          firstthreeepisode: allWpPodcast(limit: 3) {
            edges {
              node {
                id
                title
                episodeDetails {
                  author
                  date
                  duration
                  episodeUrl
                  eyebrow
                  podcastImage {
                    node {
                      altText
                      sourceUrl
                      title
                      uri
                    }
                  }
                  title
                }
              }
            }
          }
          restallepisode: allWpPodcast {
            edges {
              node {
                id
                episodeDetails {
                  author
                  date
                  duration
                  episodeUrl
                  eyebrow
                  podcastImage {
                    node {
                      altText
                      sourceUrl
                      title
                      uri
                    }
                  }
                  title
                }
                title
              }
            }
          }
        }
      `}
      render={data => <Podcast data={data} {...props} />}
    />
  )
}
Podcast.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
